<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg10 xl7>
        <v-data-table
          dense
          :headers="headers"
          :items="compAtualizacoesLista"
          class="elevation-10"
          :search="search"
          :loading="!compAtualizacoesProgress"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          sortBy="id"
          sort-desc
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-note-multiple-outline</v-icon>
              <v-toolbar-title>Atualizacoes</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSelecionarAtualizacaoEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Atualização</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="auto" md="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarAtualizacaoEdicao(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import DatasMixin from "@/mixins/DatasMixin";
// import moment from "moment";

const { mapState, mapActions } = createNamespacedHelpers("tabAtualizacoes");

export default {
  // components: { ConfirmDialogue },
  mixins: [DatasMixin],
  name: "TabAtualizacoesLista",
  data: () => ({
    search: "",
    // dialog: false,
    headers: [
      { text: "Código", value: "id", align: "start" },
      { text: "Tabela", value: "tabela", align: "start" },
      // {
      //   text: "Atualizacao",
      //   align: "start",
      //   sortable: true,
      //   value: "atualizacao",
      // },
      // { text: "Criado", value: "criado" },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState(["staErro", "staAtualizacoes", "staAtualizacaoSelecionado"]),

    compAtualizacoesLista() {
      return this.$store.state.tabAtualizacoes.staAtualizacoes;
    },
    compAtualizacoesProgress() {
      // console.log(!this.$store.state.tabAtualizacoes.staAtualizacoes.length);
      return this.$store.state.tabAtualizacoes.staAtualizacoes;
    },
  },

  created() {
    register(this.$store);
    this.actListarAtualizacoes(this.$store.state.login.licenca);
  },

  watch: {
    erro() {
      if (this.staErro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.staErro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {
    //this.obterAtualizacoes();
  },

  methods: {
    ...mapActions([
      "actListarAtualizacoes",
      "actSelecionarAtualizacao",
      "actResetarAtualizacao",
    ]),

    metPermissao(field) {
      if (this.perm.some((t) => t.field == field)) {
        const permObject = this.perm.filter((t) => t.field == field);
        if (permObject[0].group[0] == this.$store.state.login.usuario.grupo) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    // formataData(data) {
    //   return moment(data).utc().format("DD-MM-YYYY");
    // },
    // formataDataIn(data) {
    //   return moment(data).utc().format("YYYY-MM-DD");
    // },

    async metSelecionarAtualizacaoEdicao(atualizacao) {
      // this.actSelecionarAtualizacao({ atualizacao });
      // this.$router.push({ name: "TabAtualizacoesForm" });
      if (atualizacao != undefined) {
        await this.actSelecionarAtualizacao({
          id: atualizacao.id,
          licenca: this.$store.state.login.licenca,
        });
      } else {
        this.actResetarAtualizacao();
      }
      await this.$router.push({ name: "TabAtualizacoesForm" });
    },

    metVoltar() {
      this.$router.back();
    },

    metRecarregar() {
      this.actListarAtualizacoes(this.$store.state.login.licenca);
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
  },
};
</script>

<style></style>
