import * as types from "./mutation-types";

export default {
  [types.CRIAR_ATUALIZACAO]: (state, { atualizacao }) => {
    state.staAtualizacoes.push(atualizacao);
  },
  [types.EDITAR_ATUALIZACAO]: (state, { atualizacao }) => {
    const indice = state.staAtualizacoes.findIndex(
      (t) => t.id === atualizacao.id
    );
    state.staAtualizacoes.splice(indice, 1, atualizacao);
  },
  [types.DELETAR_ATUALIZACAO]: (state, { atualizacao }) => {
    const indice = state.staAtualizacoes.findIndex(
      (t) => t.id === atualizacao.id
    );
    state.staAtualizacoes.splice(indice, 1);
  },
  [types.LISTAR_ATUALIZACOES]: (state, { atualizacoes }) => {
    state.staAtualizacoes = atualizacoes;
  },
  [types.SELECIONAR_ATUALIZACAO]: (state, { atualizacao }) => {
    state.staAtualizacaoSelect = atualizacao;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
};
