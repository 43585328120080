import apiClient from "@/services/axios";

export default {
  postAtualizacao(atualizacao) {
    return apiClient.post("/tabAtualizacoes/cadastrar", atualizacao);
  },

  putAtualizacao(atualizacao) {
    return apiClient.put("/tabAtualizacoes/editar", atualizacao);
  },

  getAtualizacoesSelecionar(id, licenca) {
    return apiClient.get(`/tabAtualizacoes/selecionar/${id}/${licenca}`);
  },

  getAtualizacoesListar(licenca) {
    return apiClient.get(`/tabAtualizacoes/listar/${licenca}`);
  },
};
