import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarAtualizacao: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postAtualizacao(payload);
      payload.atualizacao.id = response.data.id;
      commit(types.CRIAR_ATUALIZACAO, { atualizacao: payload.atualizacao });
      await dispatch("actSelecionarAtualizacao", {
        id: payload.atualizacao.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarAtualizacao: async ({ commit }, payload) => {
    try {
      payload.atualizacao.criacao = undefined;
      payload.atualizacao.criado = undefined;
      payload.atualizacao.modificado = undefined;
      const response = await axios.putAtualizacao(payload);
      commit(types.EDITAR_ATUALIZACAO, {
        atualizacao: JSON.parse(response.config.data)["atualizacao"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarAtualizacao: async ({ commit }, payload) => {
    try {
      const response = await axios.getAtualizacoesSelecionar(
        payload.id,
        payload.licenca
      );
      // console.log("!!", response.data.response[0]);
      await commit(types.SELECIONAR_ATUALIZACAO, {
        atualizacao: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarAtualizacoes: async ({ commit }, licenca) => {
    try {
      const response = await axios.getAtualizacoesListar(licenca);
      commit(types.LISTAR_ATUALIZACOES, {
        atualizacoes: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  /* actLixeiraAtualizacao: async ({ dispatch }, payload) => {
    const atualizacao = Object.assign({}, payload.atualizacao);
    atualizacao.lixeira = !atualizacao.lixeira;
    atualizacao.modificacao = payload.modificacao;
    dispatch("actEditarAtualizacao", {
      atualizacao: atualizacao,
      licenca: payload.licenca,
    });
  }, */

  actResetarAtualizacao: ({ commit }) => {
    commit(types.SELECIONAR_ATUALIZACAO, { atualizacao: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};
